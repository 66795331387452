import React, { useEffect, useState, Component } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ReactBootstrap from "react-bootstrap";
import './assets/css/style.css';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import configData from './config';
import axios from 'axios';
import { LOGOUT } from './store/actions';
import Swal from 'sweetalert2';
import { NavLink, useHistory } from 'react-router-dom';

//-----------------------|| APP ||-----------------------//

const App = () => {    
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        //Swal.fire({
        //    title: 'Avertissement!',
        //    text: "Session a expir\u00e9",
        //    icon: 'info',
        //    confirmButtonText: 'OK'
        //});
        dispatcher({ type: LOGOUT });
        //axios
        //    .post( configData.API_SERVER + 'users/logout', {token: `${account.token}`}, { headers: { Authorization: `${account.token}` } })
        //    .then(function (response) {

        //        // Force the LOGOUT
        //        //if (response.data.success) {
        //            dispatcher({ type: LOGOUT });
        //        //} else {
        //        //    console.log('response - ', response.data.msg);
        //        //}
        //    })
        //    .catch(function (error) {
        //        console.log('error - ', error);
        //    });
    };

    const getData = () => {
        if(window.location.pathname == '/utilisateurs/list' && account.userRolId != 1)
        {
            return history.push('/');
        }

        if (account.token != "") {
            try {
                const requestOptions = {
                    method: 'GET',
                    token: `${account.token}`,
                    headers: { Authorization: `${account.token}` }
                };
                axios
                    .get(configData.API_SERVER + "Project/getAll", requestOptions)
                    .then(function (response) {
                        if (response.status == 200) {
                            console.log('Ok')
                        } else {
                            handleLogout();
                        }
                    })
                    .catch(function (error) {
                        handleLogout();
                    });
            } catch (err) {
                handleLogout();
            }
        }        
    }

    getData();

    //useEffect(() => {
    //    if (window.location.pathname != "/login")
    //        getData();
        
    //}, []);

    return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
    );
};

export default App;
