import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
import Logo from './../../../ui-component/Logo';
import LogoIcon from './../../../assets/images/eoliengo.jpg';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (        
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            {/*<LogoIcon />*/}
            <img src={LogoIcon} alt="Logo" className="logo" />
        </ButtonBase>
    );
};

export default LogoSection;
