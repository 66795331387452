// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from './actions';

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    userRolId: null,
    nom: null,
    prenom: null,
    email: null,
    image: null,
    userRol: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token, userRolId, nom, prenom, email, image, userRol } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                token,
                user,
                userRolId, nom, prenom, email, image, userRol
            };
        }
        case LOGIN: {
            const { user, userRolId, nom, prenom, email, image, userRol } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user,
                userRolId, nom, prenom, email, image, userRol
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: null,
                userRolId: null,
                userRol: null,
                nom: null,
                prenom: null,
                email: null,
                image: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
