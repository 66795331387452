import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configData from '../../../../config';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Switch,
    Typography
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import { LOGOUT } from './../../../../store/actions';

// assets
import { IconLogout, IconSearch, IconSettings } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';
import blackSettings from './../../../../assets/images/icons/black-settings.png';

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main + '!important',
            color: theme.palette.primary.light,
            '& svg': {
                stroke: theme.palette.primary.light
            }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.warning.dark,
        color: '#fff'
    }
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const [sdm, setSdm] = React.useState(true);
    const [value, setValue] = React.useState('');
    const [notification, setNotification] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [ImagenUser, setImagenUser] = React.useState(User1);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleLogout = () => {
        console.log(account.token);
        dispatcher({ type: LOGOUT });        
        //axios
        //    .post( configData.API_SERVER + 'users/logout', {token: `${account.token}`}, { headers: { Authorization: `${account.token}` } })
        //    .then(function (response) {
                
        //        // Force the LOGOUT
        //        //if (response.data.success) {
        //            dispatcher({ type: LOGOUT });
        //        //} else {
        //        //    console.log('response - ', response.data.msg);
        //        //}
        //    })
        //    .catch(function (error) {
        //        console.log('error - ', error);
        //    });
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        if (account.image != "" && account.image != undefined && account.image != null && account.image != 'null') {
            setImagenUser("data:image/png;base64," + account.image);
        }            

        prevOpen.current = open;
    }, [open]);
    return (
        <React.Fragment className="LOLOOOOOOO">
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                icon={
                    <>
                    <Avatar
                        src={ImagenUser}
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        />
                        <div className="user-log ml-10">                            
                            <strong className="user-name color-black">{account.prenom} {account.nom}</strong>
                            <strong className="user-rol gray-color">{account.userRol}</strong>
                        </div>
                    
                    </>
                }
                // label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                label={<img id="settingIcon" src={blackSettings}/>}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />           
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard className="container-menu-profile" border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={0}>
                                            <Grid item className={classes.flex}>
                                                <Typography variant="h4">Bonjour,</Typography>
                                                <Typography component="span" variant="h4" className={classes.name}>
                                                    {account.prenom} {account.nom}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2">{account.userRol}</Typography>
                                            </Grid>
                                        </Grid>
                                        {/*<OutlinedInput*/}
                                        {/*    className={classes.searchControl}*/}
                                        {/*    id="input-search-profile"*/}
                                        {/*    value={value}*/}
                                        {/*    onChange={(e) => setValue(e.target.value)}*/}
                                        {/*    placeholder="Search profile options"*/}
                                        {/*    startAdornment={*/}
                                        {/*        <InputAdornment position="start">*/}
                                        {/*            <IconSearch stroke={1.5} size="1.3rem" className={classes.startAdornment} />*/}
                                        {/*        </InputAdornment>*/}
                                        {/*    }*/}
                                        {/*    aria-describedby="search-helper-text"*/}
                                        {/*    inputProps={{*/}
                                        {/*        'aria-label': 'weight'*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <Divider />
                                        {/*<PerfectScrollbar className={classes.ScrollHeight}>*/}
                                            {/*<UpgradePlanCard />*/}
                                            {/*<Divider />*/}
                                            {/*<Card className={classes.card}>*/}
                                            {/*    <CardContent>*/}
                                            {/*        <Grid container spacing={3} direction="column">*/}
                                            {/*            <Grid item>*/}
                                            {/*                <Grid item container alignItems="center" justifyContent="space-between">*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Typography variant="subtitle1">Start DND Mode</Typography>*/}
                                            {/*                    </Grid>*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Switch*/}
                                            {/*                            color="primary"*/}
                                            {/*                            checked={sdm}*/}
                                            {/*                            onChange={(e) => setSdm(e.target.checked)}*/}
                                            {/*                            name="sdm"*/}
                                            {/*                            size="small"*/}
                                            {/*                        />*/}
                                            {/*                    </Grid>*/}
                                            {/*                </Grid>*/}
                                            {/*            </Grid>*/}
                                            {/*            <Grid item>*/}
                                            {/*                <Grid item container alignItems="center" justifyContent="space-between">*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Typography variant="subtitle1">Allow Notifications</Typography>*/}
                                            {/*                    </Grid>*/}
                                            {/*                    <Grid item>*/}
                                            {/*                        <Switch*/}
                                            {/*                            checked={notification}*/}
                                            {/*                            onChange={(e) => setNotification(e.target.checked)}*/}
                                            {/*                            name="sdm"*/}
                                            {/*                            size="small"*/}
                                            {/*                        />*/}
                                            {/*                    </Grid>*/}
                                            {/*                </Grid>*/}
                                            {/*            </Grid>*/}
                                            {/*        </Grid>*/}
                                            {/*    </CardContent>*/}
                                            {/*</Card>*/}
                                            <Divider />
                                            <List component="nav" className={classes.navContainer}>
                                                <ListItemButton
                                                    className="logout-button"
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography className="logout">Déconnexion</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        {/*</PerfectScrollbar>*/}
                                    </CardContent>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default ProfileSection;
